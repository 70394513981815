// grecaptcha:true
import videoask from './embed'
import Swiper, { Navigation, Autoplay, Scrollbar } from 'swiper'

Swiper.use([Navigation, Autoplay, Scrollbar])

window.$ = window.jQuery = require('jquery')
window.popper = require('popper.js')
window.bootstrap = require('bootstrap')
window.AOS = require('aos')
window.moment = require('moment')
require('jquery-parallax.js')
require('tempusdominus-bootstrap-4')
require('bootstrap-select')
require('bootstrap-autocomplete')
require('lightcase')
require('twbs-pagination')
window.bootbox = require('bootbox')
window.lazyframe = require('lazyframe')

// require('cookieconsent')

const btnCollapse = document.querySelector('.btn-pearl[data-target="#collapse-info"]');
if (btnCollapse) {
  btnCollapse.addEventListener('click', event => {
    btnCollapse.classList.add('d-none')
  })
}
$('#collapse-info').on('shown.bs.collapse', function () {
  window.dispatchEvent(new Event('resize'))
})

const elements = document.querySelectorAll('.lazyframe')
lazyframe(elements, {
  lazyload: false
})

window.videoask.loadEmbed({
  kind: 'widget',
  url: 'https://www.videoask.com/fcuud7qsx',
  options: {
    widgetType: 'VideoThumbnailLarge',
    text: 'Kontakt',
    backgroundColor: '#c1c004',
    position: 'top-left'
  }
})

const iconSwiper = new Swiper('.icon-swiper', {
  // Optional parameters
  direction: 'horizontal',
  loop: true,
  slidesPerView: 1,
  spaceBetween: 30,
  centeredSlides: true,

  breakpoints: {
    768: {
      slidesPerView: 3
    },
    1200: {
      slidesPerView: 5
    }
  },

  autoplay: {
    delay: 1500
  },

  scrollbar: false,

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  }
})

const testimonialsSwiper = new Swiper('.testimonials-swiper', {
  // Optional parameters
  direction: 'horizontal',
  // loop: true,
  slidesPerView: 1,
  centeredSlides: true,

  autoplay: {
    delay: 5000
  },

  scrollbar: {
    el: '.swiper-scrollbar'
  }
})

$('.dropdown-item.active').parent().siblings('.nav-link').addClass('active')

AOS.init()

// When the user scrolls down 20px from the top of the document, show the button
window.onscroll = function () {
  scrollFunction()
}

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    document.getElementById('topButton').style.display = 'flex'
  } else {
    document.getElementById('topButton').style.display = 'none'
  }
}

// When the user clicks on the button, scroll to the top of the document
// eslint-disable-next-line no-unused-vars
window.topFunction = function () {
  document.body.scrollTop = 0 // For Safari
  document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
}

$(document).ready(function () {
  $('body').on('click', '.btn-submit', function (e) {
    console.log('send & check form');
    e.preventDefault()
    var $this = $(this)
    var $form = $(e.target.form)

    // Entferne das alte reCAPTCHA-Token, falls vorhanden
    $form.find('input[name="g-recaptcha-response"]').remove();

    grecaptcha.ready(function () {
      grecaptcha.execute('6LeKVKoUAAAAAP2B5xR-hEb119SMyMoeM9oYZBGJ', { action: 'homepage' }).then(function (token) {
        // add token to form
        $form.prepend('<input type="hidden" name="g-recaptcha-response" value="' + token + '">')

        var $data = $form.serializeArray()
        var $url = $form.attr('action')
        var $val = $this.val()

        $data.push({ name: 'token', value: token })

        console.log($data)

        $.ajax({
          url: $url,
          data: $data,
          method: 'post',
          dataType: 'json',
          beforeSend: function () {
            $this.removeClass('btn-pearl').addClass('btn-pearl-clicked').val('Daten werden gesendet')
          },
          success: function (data) {
            if (data.status === 200 || data.status === 'success') {
              if (data.type === 'callback') {
                location.href = 'rueckruf-danke.php'
              } else {
                location.href = 'danke.php'
              }
            } else {
              $this.addClass('btn-pearl').removeClass('btn-pearl-clicked').val($val)
              bootbox.dialog({
                message: '<p>' + data.msg + '</p>',
                onEscape: true,
                onBackdrop: true,
                buttons: {
                  ok: {
                    label: 'Okay',
                    className: 'btn-pearl',
                    callback: () => {

                    }
                  }
                }
              })
            }
          } // success funtcion
        }) // ajax
      }) // grecaptcha
    }) // grecaptcha
  }) // click form submit

  $('#date-field').datetimepicker({
    format: 'L',
    daysOfWeekDisabled: [0, 6],
    locale: 'de',
    useCurrent: false,
    minDate: moment()
  })

  $('#date-field-promotion-von').datetimepicker({
    locale: 'de',
    format: 'L',
    useCurrent: false,
    minDate: moment(),

  })

  $('#date-field-promotion-bis').datetimepicker({
    locale: 'de',
    format: 'L',
    useCurrent: false,
    minDate: moment(),
  })

  $("#date-field-promotion-von").on("change.datetimepicker", function (e) {
    $('#date-field-promotion-bis').datetimepicker('minDate', e.date)
  });
  $("#date-field-promotion-bis").on("change.datetimepicker", function (e) {
    $('#date-field-promotion-von').datetimepicker('maxDate', e.date)
  });

  $('#time-field-promotion-von').datetimepicker({
    locale: 'de',
    format: 'LT',
    stepping: 30
  })

  $('#time-field-promotion-bis').datetimepicker({
    locale: 'de',
    format: 'LT',
    stepping: 30
  })

  $('#time-field').datetimepicker({
    format: 'LT',
    locale: 'de',
    stepping: 30,
    disabledTimeIntervals: [[moment({ h: 0 }), moment({ h: 9 })], [moment({ h: 17, m: 15 }), moment({ h: 24 })]]
  })

  $('a[data-rel^=lightcase]').lightcase({
    showCaption: true,
    swipe: true,
    labels: {
      'sequenceInfo.of': ' von '
    },
    maxWidth: 1440,
    maxHeight: 702
  })

  $('.parallax-container').each((ind, elem) => {
    if ($(elem).find('.parallax-slider').data('positionY') !== undefined) {
      $(elem).parallax({
        naturalWidth: 600,
        naturalHeight: 400,
        positionY: $(elem).find('.parallax-slider').data('positionY')
      })
    } else {
      $(elem).parallax({
        naturalWidth: 600,
        naturalHeight: 400
      })
    }
  })

  $('#pagination-logos').twbsPagination({
    totalPages: 8,
    // the current page that show on start
    startPage: 1,

    // maximum visible pages
    visiblePages: 8,

    initiateStartPageClick: true,

    // template for pagination links
    href: false,

    // variable name in href template for page number
    hrefVariable: '{{number}}',

    // Text labels
    prev: '<span aria-hidden="true">&laquo;</span>',
    next: '<span aria-hidden="true">&raquo;</span>',
    first: 'Erste',
    last: 'Letzte',

    // carousel-style pagination
    loop: false,

    // callback function
    onPageClick: function (event, page) {
      $('.page-logo-active').removeClass('page-logo-active')
      $('#page' + page).addClass('page-logo-active')
      $('.first').remove()
      $('.last').remove()
    },

    // pagination Classes
    paginationClass: 'pagination',
    nextClass: 'next',
    prevClass: 'prev',
    lastClass: 'last',
    firstClass: 'first',
    pageClass: 'page',
    activeClass: 'active',
    disabledClass: 'disabled'
  })
  $('.first').remove()
  $('.last').remove()

  const observer = new IntersectionObserver(entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const img = $(entry.target).data('src')
        $(entry.target).attr('src', img)
      }
    })
  }, {
    rootMargin: '150px 0px 150px 0px'
  })

  const lazyImages = [...document.getElementsByClassName('lazy-img')]

  lazyImages.forEach(lazyImage => {
    observer.observe(lazyImage)
  })
})
